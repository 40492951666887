@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1a;
  color: #ffffff;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  background-color: #1a1a1a;
}

/* Smooth scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background: #4a90e2;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2a6fc7;
}

/* Reading interface styles */
.reading-interface {
  max-width: 800px;
  margin: 0 auto;
}

.chapter-banner {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.chapter-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chapter-banner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.top-navigation {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.back-arrow {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(74, 144, 226, 0.7);
  border-radius: 50%;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.back-arrow:hover {
  background-color: #2a6fc7;
}

.back-arrow svg {
  width: 24px;
  height: 24px;
}

.chapter-title {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  color: #ffffff;
  text-align: center;
  z-index: 2;
  margin: 0;
  font-size: 24px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.content {
  font-size: 18px;
  line-height: 1.6;
  text-align: left;
  padding-top: .5rem;
}

.chapter-text {
  margin-bottom: 1.5em;
  transition: color 0.3s ease, transform 0.3s ease;
}

.chapter-text:hover {
  color: #4a90e2;
  transform: translateY(-2px);
}

.content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 2em auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Webnovel page styles */
.webnovel-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.webnovel-page .chapter-banner {
  margin: -20px -20px 20px -20px;
  height: 200px;
}

.webnovel-title {
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
}

.synopsis {
  font-style: italic;
  margin-bottom: 40px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #ffffff;
}

/* Chapter list styles */
.chapter-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #222;
  border-radius: 0;
  overflow: hidden;
}

.chapter-item {
  border-bottom: 1px solid #333;
}

.chapter-item:last-child {
  border-bottom: none;
}

.chapter-link {
  display: flex;
  align-items: stretch;
  text-decoration: none;
  color: #fff;
  padding: 15px;
  transition: background-color 0.3s ease;
}

.chapter-link:hover {
  background-color: #333;
}

.chapter-thumbnail {
  width: 80px;
  height: auto;
  object-fit: contain;
  margin-right: 15px;
}

.chapter-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chapter-list .chapter-title {
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  text-align: left;
  margin: 0;
  position: static;
}

.chapter-number {
  font-weight: bold;
  color: #bbbbbb;
  font-size: 16px;
  margin-left: auto;
  padding-left: 10px;
}

.chapter-navigation {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #333;
  margin-top: 40px;
}

.nav-button {
  padding: 10px 15px;
  background-color: #4a90e2;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.nav-button:hover {
  background-color: #2a6fc7;
}

.nav-button.home {
  background-color: #333;
}

.nav-button.home:hover {
  background-color: #444;
}

@media (max-width: 768px) {
  .webnovel-page, .reading-interface {
    padding: 0;
  }

  .webnovel-page .chapter-banner, .chapter-banner {
    margin: 0 0 20px 0;
    height: 200px;
  }

  .content {
    padding: 0 20px;
  }

  .chapter-link {
    padding: 15px;
  }

  .chapter-thumbnail {
    width: 70px;
  }

  .chapter-list .chapter-title {
    font-size: 16px;
  }
}

html {
  scroll-behavior: smooth;
}

.home-page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
}

.chapter-banner.homepage-banner {
  position: relative;
  width: 100%;
  height: 300px; /* Fixed height for desktop */
  overflow: hidden;
  margin-bottom: 20px;
}

.chapter-banner.homepage-banner img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.chapter-banner.homepage-banner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .15);
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-content .site-title {
  color: #ffffff;
  margin: 0 0 20px 0;
  font-size: 3rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.banner-content .site-subtitle {
  color: #ffffff;
  margin: 0;
  font-size: 1.5rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, .8);
  max-width: 80%;
}

.webnovel-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  padding: 0 20px;
}

.webnovel-tile {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.webnovel-tile:hover {
  transform: translateY(-5px);
}

.webnovel-link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.webnovel-cover {
  width: 100%;
  aspect-ratio: 2 / 3;
  object-fit: cover;
}

.webnovel-title {
  padding: 10px;
  margin: 0;
  font-size: 1rem;
  text-align: center;
}

.webnovel-synopsis {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.webnovel-tile:hover .webnovel-synopsis {
  opacity: 1;
}

@media (max-width: 1024px) {
  .chapter-banner.homepage-banner {
    height: 250px;
  
  .webnovel-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media (max-width: 768px) {
  .webnovel-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    padding: 0 15px;
  }
  
  .chapter-banner.homepage-banner {
    height: 200px;
  }
  
  .banner-content .site-title {
    font-size: 3rem;
    margin-bottom: 15px;
  }

  .banner-content .site-subtitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .webnovel-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 10px;
  }
  
  .chapter-banner.homepage-banner {
    height: 150px;
  }
  
  .banner-content .site-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .banner-content .site-subtitle {
    font-size: 1rem;
  }
}